import React from "react";
import {
  Box,
  Button,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { setAdminDashData } from "./variables/functions";
import { AdminContext } from "./contexts/AdminContext";

export default function ChangePassword(props) {
  const [show2, setshow2] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const admin = React.useContext(AdminContext);
  const toast = useToast();
  function submitForm(e) {
    e.preventDefault();
  }
  return (
    <Box px="10px">
      <Box
        w="100%"
        maxW="500px"
        mx="auto"
        my="20px"
        p="24px"
        borderRadius="30px"
        backgroundColor="white"
      >
        <Text fontWeight="bold" textAlign="start" fontSize="2xl">
          Change Password
        </Text>
        <form onSubmit={submitForm}>
          <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
            New Password<Text>*</Text>
          </FormLabel>
          <InputGroup size="md">
            <Input
              isRequired={true}
              fontSize="sm"
              placeholder="Enter your password"
              mb="24px"
              size="lg"
              type={show2 ? "text" : "password"}
              variant="auth"
              fontWeight="500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement display="flex" alignItems="center" mt="4px">
              <Icon
                _hover={{ cursor: "pointer" }}
                onClick={() => setshow2(!show2)}
                as={show2 ? RiEyeCloseLine : MdOutlineRemoveRedEye}
              />
            </InputRightElement>
          </InputGroup>
          <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
            Confirm New Password<Text>*</Text>
          </FormLabel>
          <InputGroup size="md">
            <Input
              isRequired={true}
              fontSize="sm"
              placeholder="Enter your password"
              mb="24px"
              size="lg"
              type={"password"}
              variant="auth"
              fontWeight="500"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </InputGroup>
          <Button
            fontSize="sm"
            fontWeight="500"
            variant="brand"
            type="submit"
            bg="#e70049"
            color="white"
            w="100%"
            h="50"
            _hover={{ bg: "#e70049" }}
            _active={{ bg: "#e70049" }}
            _focus={{ bg: "#e70049" }}
            mt="14px"
            onClick={() => {
              if (password !== confirmPassword || password === "") {
                toast({
                  title: "Sorry",
                  description: "Password and confirm password does not match",
                  status: "error",
                  containerStyle: {
                    backgroundColor: "red",
                    colorScheme: "red",
                  },
                  duration: 9000,
                  isClosable: true,
                });
                return;
              }
              setAdminDashData(
                admin.user.username,
                admin.user.token,
                "setAdminPassword",
                {
                  password: password,
                }
              ).then((data) => {
                if (data.error.code === "#200") {
                  toast({
                    title: "Success",
                    description: "Password changed successfully",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                  });
                  setPassword("");
                  setConfirmPassword("");
                } else {
                  toast({
                    title: "Sorry",
                    description: data.error.message,
                    status: "error",

                    duration: 9000,
                    isClosable: true,
                  });
                }
              });
            }}
          >
            Change Password
          </Button>
        </form>
      </Box>
    </Box>
  );
}
