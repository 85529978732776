/*eslint-disable*/
import React from "react";
import { Text, Center } from "@chakra-ui/react";

export default function Footer() {
  return (
    <Center pb="30px">
      <Text
        color="white"
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        {" "}
        &copy; {1900 + new Date().getYear()}
        <Text as="span" fontWeight="500" ms="4px" color="white">
          Narpavie Enterprises Limited. All Rights Reserved.
        </Text>
      </Text>
    </Center>
  );
}
