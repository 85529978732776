// Chakra imports
import { Box, CircularProgress, Text, Center } from "@chakra-ui/react";
import NavbarAdmin from "./components/navbar/NavbarAdmin.js";
import { AdminContext } from "./contexts/AdminContext";
import React, { useState } from "react";
import { getAdminDashData } from "./variables/functions";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "./routes.js";

export default function Admin(props) {
  const { ...rest } = props;
  const [fixed] = useState(false);
  const [user, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
    var username = sessionStorage.getItem("username");
    var token = sessionStorage.getItem("token");
    getAdminDashData(username, token, "getInvestorDetails").then((data) => {
      if (data.error.code === "#200") {
        data.data.token = token;
        setUserDetails(data.data);
        setLoading(false);
      } else {
        sessionStorage.clear();
        window.location.href = "/auth";
      }
    });
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  return (
    <Box>
      {!loading ? (
        <AdminContext.Provider value={{ user, setUserDetails, setLoading }}>
          <Box
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Box>
              <NavbarAdmin
                logoText={"Mahabhojanam"}
                brandText={"Welcome to MAHA BHOJANAM"}
                fixed={fixed}
                {...rest}
              />
            </Box>
            <Box>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/" to="/admin/dashboard" />
              </Switch>
            </Box>
          </Box>
        </AdminContext.Provider>
      ) : (
        <Center>
          <div style={{ marginTop: "50vh" }}></div>
          <CircularProgress isIndeterminate color="white"></CircularProgress>
          <br />
          <Text ml="2">Loading...</Text>
        </Center>
      )}
    </Box>
  );
}
