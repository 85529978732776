import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  AlertDialogOverlay,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useDisclosure } from "@chakra-ui/react";
import { getLoginFromServer } from "./variables/functions";
import { Redirect, useHistory } from "react-router-dom";

export default function Auth() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const [show, setShow] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const history = useHistory();
  const [errorDescription, setErrorDescription] = React.useState("");
  function submitForm(e) {
    e.preventDefault();
    getLoginFromServer(username, password).then((data) => {
      if (data.error.code === "#200") {
        sessionStorage.setItem("token", data.data.token);
        sessionStorage.setItem("username", username);
        history.push("/admin");
      } else {
        setErrorDescription(data.error.description);
        onOpen();
      }
    });
  }
  return (
    <Center>
      <div class="repeatedImage"></div>
      {sessionStorage.getItem("token") ? <Redirect to="/admin" /> : null}
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box
          me="auto"
          w="100%"
          maxW="500px"
          mx="auto"
          my="20px"
          p="24px"
          borderRadius="30px"
          backgroundColor="white"
        >
          <Heading color={textColor} fontSize="36px" mb="10px">
            Maha Bhojanam Investors
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Please enter your Investor Username and password to login !
          </Text>

          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <form onSubmit={submitForm}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Username <Text>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter your username"
                mb="24px"
                fontWeight="500"
                size="lg"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Enter your password"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  fontWeight="500"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    onClick={() => setShow(!show)}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  />
                </InputRightElement>
              </InputGroup>
              <Button
                fontSize="sm"
                fontWeight="500"
                variant="brand"
                type="submit"
                bg="#e70049"
                color="white"
                w="100%"
                h="50"
                _hover={{ bg: "#e70049" }}
                _active={{ bg: "#e70049" }}
                _focus={{ bg: "#e70049" }}
              >
                Sign In
              </Button>
            </form>
          </Flex>
        </Box>
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Sorry
            </AlertDialogHeader>

            <AlertDialogBody>{errorDescription}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="red" onClick={onClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Center>
  );
}
