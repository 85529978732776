import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdPerson, MdSettings } from "react-icons/md";
import ChangePassword from "./changepassword";
import Profile from "./profile";
import Dashboard from "./dashboard";

const sidebarRoutes = [
  {
    name: "Change Password",
    secondary: "Profile",
    layout: "/admin",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    path: "/change-password",
    component: ChangePassword,
    isHide: true,
  },
  {
    name: "Dashboard",
    secondary: "Proifle",
    layout: "/admin",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    path: "/dashboard",
    component: Dashboard,
    isHide: true,
  },
  {
    name: "Profile",
    secondary: "Profile",
    layout: "/admin",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: "/profile",
    component: Profile,
    isHide: true,
  },
];
const routes = [...sidebarRoutes];

export default routes;
export { sidebarRoutes };
