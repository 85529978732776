import {
  Center,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Card from "./Card";
export default function ReportStatistics(props) {
  const { startContent, endContent, name, todayvalue, weekvalue, monthvalue } =
    props;
  const textColorSecondary = "secondaryGray.600";

  return (
    <Card py="15px">
      <Flex
        my="auto"
        flexDirection={{
          base: "column",
          md: "row",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Center ms={startContent ? "0px" : "18px"}>{startContent}</Center>
        <Stat my="auto" ms={startContent ? "18px" : "0px"}>
          <StatLabel
            pt={{ base: "10px", md: "0px" }}
            textAlign={{
              base: "center",
              md: "start",
            }}
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{
              base: "md",
            }}
          >
            {name}
          </StatLabel>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="10px">
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Text color="green.500" fontSize="xl" fontWeight="700" me="5px">
                {todayvalue}
              </Text>
              <Text as="span" fontSize="xs" color="secondaryGray.600">
                Today
              </Text>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Text color="green.500" fontSize="xl" fontWeight="700" me="5px">
                {weekvalue}
              </Text>
              <Text as="span" fontSize="xs" color="secondaryGray.600">
                This Week
              </Text>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Text color="green.500" fontSize="xl" fontWeight="700" me="5px">
                {monthvalue}
              </Text>
              <Text as="span" fontSize="xs" color="secondaryGray.600">
                This Month
              </Text>
            </div>
          </SimpleGrid>
        </Stat>
        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
