import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Auth from "./auth.js";
import theme from "./theme/theme";
import Admin from "./admin";
import Footer from "./components/footer/FooterAdmin";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/auth" component={Auth} />
          <Redirect from="/" to="/auth" />
        </Switch>
        <Footer />
      </BrowserRouter>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
