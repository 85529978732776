import {
  Box,
  FormLabel,
  Input,
  InputGroup,
  Text,
} from "@chakra-ui/react";

import React from "react";
import { AdminContext } from "./contexts/AdminContext";

export default function Profile(props) {
  function submitForm(e) {
    e.preventDefault();
  }
  const admin = React.useContext(AdminContext);

  const [name, setName] = React.useState(admin.user.name);
  const [email, setEmail] = React.useState(admin.user.email);
  const [phone, setPhone] = React.useState(admin.user.telephone);
  return (
    <Box px="10px">
      <Box
        w="100%"
        maxW="500px"
        mx="auto"
        my="20px"
        p="24px"
        borderRadius="30px"
        backgroundColor="white"
      >
        <Text fontWeight="bold" textAlign="start" fontSize="2xl">
          Your Profile
        </Text>
        <form onSubmit={submitForm}>
          <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
            Name<Text></Text>
          </FormLabel>
          <InputGroup size="md">
            <Input
              isRequired={true}
              readOnly
              fontSize="sm"
              placeholder="Enter your Name"
              mb="24px"
              size="lg"
              type="text"
              variant="auth"
              fontWeight="500"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>
          <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
            Email<Text></Text>
          </FormLabel>
          <InputGroup size="md">
            <Input
              isRequired={true}
              readOnly
              fontSize="sm"
              placeholder="Enter your password"
              mb="24px"
              size="lg"
              type="email"
              variant="auth"
              fontWeight="500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
          <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
            Phone<Text>*</Text>
          </FormLabel>
          <InputGroup size="md">
            <Input
              isRequired={true}
              readOnly
              fontSize="sm"
              placeholder="Enter your phone"
              mb="24px"
              size="lg"
              type="number"
              variant="auth"
              fontWeight="500"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </InputGroup>
       </form>
      </Box>
    </Box>
  );
}
