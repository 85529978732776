import { AdminContext } from "./contexts/AdminContext";
import React from "react";
import { getAdminDashData } from "./variables/functions";
import ReportStatistics from "./components/ReportStatistics.js";
import { Box, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import IconBox from "./components/icons/IconBox";

import { FaMoneyBillWave, FaReceipt, FaUsers } from "react-icons/fa";
import { MdSell } from "react-icons/md";
import { TbBoxMargin, TbDiscount2 } from "react-icons/tb";

import { boxBg } from "./variables/constants";
export default function Dashboard() {
  const admin = React.useContext(AdminContext);
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    console.log(admin);
    getAdminDashData(
      admin.user.username,
      admin.user.token,
      "getReportStatsDetails",
      {
        type: "0",
      }
    ).then((data) => {
      if (data.error.code === "#200") {
        setData(data.data);
      } else {
        console.log(data.error);
      }
    });
  }, [admin]);
  return (
    <>
      {data && (
        <>
          <Box p="20px">
            <Text fontSize="2xl" fontWeight="bold" mb="20px" color="white">
              Active Users Reports
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2, "2xl": 3 }} gap="20px">
              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={FaUsers} />}
                  />
                }
                name="Active Users"
                todayvalue={data.active.today.users}
                monthvalue={data.active.month.users}
                weekvalue={data.active.week.users}
              />
              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={FaReceipt} />}
                  />
                }
                name="GST"
                todayvalue={data.active.today.gst}
                monthvalue={data.active.month.gst}
                weekvalue={data.active.week.gst}
              />
              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={MdSell} />}
                  />
                }
                name="Turnover"
                todayvalue={data.active.today.sales}
                monthvalue={data.active.month.sales}
                weekvalue={data.active.week.sales}
              />
              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={TbBoxMargin} />}
                  />
                }
                name="Shared MOI Revenue"
                todayvalue={data.active.today.marginDistribution}
                monthvalue={data.active.month.marginDistribution}
                weekvalue={data.active.week.marginDistribution}
              />
              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={FaMoneyBillWave} />}
                  />
                }
                name="Net Income"
                todayvalue={data.active.today.netIncome}
                monthvalue={data.active.month.netIncome}
                weekvalue={data.active.week.netIncome}
              />
              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={TbDiscount2} />}
                  />
                }
                name="Discount"
                todayvalue={data.active.today.discount}
                monthvalue={data.active.month.discount}
                weekvalue={data.active.week.discount}
              />
              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={TbDiscount2} />}
                  />
                }
                name="Instant Credit"
                todayvalue={data.active.today.instantCredit}
                monthvalue={data.active.month.instantCredit}
                weekvalue={data.active.week.instantCredit}
              />

              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={TbDiscount2} />}
                  />
                }
                name="Invitee Offer Credit for Activation"
                todayvalue={data.active.today.inviteeCredit}
                monthvalue={data.active.month.inviteeCredit}
                weekvalue={data.active.week.inviteeCredit}
              />

              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={TbDiscount2} />}
                  />
                }
                name="User Live Credit for Activation"
                todayvalue={data.active.today.userCredit}
                monthvalue={data.active.month.userCredit}
                weekvalue={data.active.week.userCredit}
              />

              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={TbDiscount2} />}
                  />
                }
                name="Invitee Live Credit for Registration"
                todayvalue={data.active.today.registerUserCredit}
                monthvalue={data.active.month.registerUserCredit}
                weekvalue={data.active.week.registerUserCredit}
              />

              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={TbDiscount2} />}
                  />
                }
                name="Invitee Live Credit for Registration"
                todayvalue={data.active.today.registerInviteeCredit}
                monthvalue={data.active.month.registerInviteeCredit}
                weekvalue={data.active.week.registerInviteeCredit}
              />
            </SimpleGrid>
          </Box>
          <Box p="20px">
            <Text fontSize="2xl" fontWeight="bold" mb="20px" color="white">
              Free Users Reports
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2, "2xl": 3 }} gap="20px">
              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={FaUsers} />}
                  />
                }
                name="Free Users"
                todayvalue={data.free.today.users}
                monthvalue={data.free.month.users}
                weekvalue={data.free.week.users}
              />
              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={TbDiscount2} />}
                  />
                }
                name="Invitee Credit for Registration"
                todayvalue={data.free.today.registerUserCredit}
                monthvalue={data.free.month.registerUserCredit}
                weekvalue={data.free.week.registerUserCredit}
              />
              <ReportStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="32px" color={"#e70049"} h="32px" as={TbDiscount2} />}
                  />
                }
                name="Invitee Credit for Registration"
                todayvalue={data.free.today.registerInviteeCredit}
                monthvalue={data.free.month.registerInviteeCredit}
                weekvalue={data.free.week.registerInviteeCredit}
              />
            </SimpleGrid>
          </Box>
        </>
      )}
    </>
  );
}
